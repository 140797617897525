import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Otolaryngology() {
  const title = "Otolaryngology";
  const subTitle = "耳鼻咽喉科";
  const overview = [
    "高齢者から子供まで幅広い年齢層の患者様が来院される診療科目です",
    <br />,
    "場所によっては小児科も標榜し、小児の患者様も取り込んでいくことがあります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　2,000〜2,500万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、電動イス、X線撮影機器",
    <br />,
    "DICOM（ダイコム）画像を見られる高精細モニターとPACS、聴力検査室、専用内視鏡 など",
  ];
  const point = "ポイント";
  const pointText = [
    "・視認性が良い立地を選択する",
    <br />,
    "・洗浄、消毒設備の確保",
    <br />,
    "・診療内容のすり合わせが必要",
    <br />,
    <br />,
    "受信する姿を見られても困る診療科目ではないため、高齢者から子供が通いやすい低階層の部物件が望ましいです",
    <br />,
    "内科や小児科と競合になる場合もあるため、診療内容のすりあわせや診療圏の調査が必須です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
